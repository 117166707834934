import PropTypes from 'prop-types';
import HomeSearch from './HomeSearch';
import Advantages from './Advantages';
import SubscribeSection from './SubscribeSection';
import PopularServices from './PopularServices';
import useTranslation from 'next-translate/useTranslation';
import LazyLoad from 'react-lazyload';
import dynamic from "next/dynamic";
const VideoBox = dynamic(() => import('shared/modules/VideoBox'));
const VideoSlider = dynamic(() => import('./VideoSlider'), {
  ssr: false,
});
const SliderBestServices = dynamic(() => import('./Sliders/services'), {
  ssr: false,
});
const Media = dynamic(() => import('./Media'));
const Projects = dynamic(() => import('./Projects'), {
  ssr: false,
});

function Home(props) {
  const { t, lang } = useTranslation('home');
  const playlist = {
    ru: [
      {
        link: '_tByBC_CNx0',
      },
      {
        link: 'abNK6ZTyAdw',
      },
    ],
    en: [
      {
        link: 'Fvk7HpMdJdc',
      },
    ],
  };

  return (
    <>
      <HomeSearch />
      <Advantages />
      <LazyLoad offset={150} once>
        <VideoBox
          slider
          playlist={playlist[lang]}
          title={t('home.video.title')}
          description={t('home.video.description')}
          withBg
          textCenter
        />
      </LazyLoad>
      <PopularServices list={props.mostPopularList} />
      <LazyLoad offset={150} once>
        <Media />
      </LazyLoad>
      <SubscribeSection blogList={props.blogList} />
      <LazyLoad offset={150} once>
        <Projects />
      </LazyLoad>
      <LazyLoad offset={150} once>
        <SliderBestServices />
      </LazyLoad>
      <LazyLoad offset={150} once>
        <VideoSlider />
      </LazyLoad>
    </>
  );
}

Home.propTypes = {
  countryGroupsData: PropTypes.array,
  mostPopularList: PropTypes.array,
  newsList: PropTypes.array,
  blogList: PropTypes.array
};

export default Home;
